import React, {useEffect} from 'react';
import M from 'materialize-css';

const Main = () => {
    useEffect(() => {
        const winheight = window.innerHeight - 96;
        let elements = document.querySelectorAll('.slider');
        M.Slider.init(elements, {indicators: false, duration: 1000, height: winheight});
    },[])
    return (
        <div className="slider">
            <ul className="slides">
                <li>
                    <img className="slideimage" src="images/aaron-burden-ob6O_xd67O0-unsplash.jpg" alt=""/>
                    <div className="caption center-align">
                        <h1 className="grey-text text-lighten-3"><b>É preciso brincar <br/> para ter imaginação</b></h1>
                    </div>
                </li>
                <li>
                    <img className="slideimage" src="images/barrett-ward-1qQx3LHaYGg-unsplash.jpg" alt=""/>
                    <div className="caption left-align">
                        <h1 className="red-text text-darken-3"><b>É preciso brincar <br/> para ter imaginação</b></h1>
                    </div>
                </li>
                <li>
                    <img className="slideimage" src="images/jeff-kingma-ND-5eIFKeiM-unsplash.jpg" alt=""/>
                    <div className="caption right-align">
                        <h1 className="blue-text text-darken-3"><b>É preciso brincar <br/> para ter imaginação</b></h1>
                    </div>
                </li>
                <li>
                    <img className="slideimage" src="images/susan-holt-simpson-H7SCRwU1aiM-unsplash.jpg" alt=""/>
                    <div className="caption left-align">
                        <h1 className="grey-text text-lighten-3"><b>É preciso brincar <br/> para ter imaginação</b></h1>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Main;