import React from 'react';

const Footer = () => {
    return (
        <footer className="page-footer black">
            <div className="container footer-copyright black">
                <div className="center-align grey-text"><small>Copyright &copy;2020 Tibuba</small></div>
                <div className="right-align">
                    <small><a href="/privacy" className="grey-text">Privacy Policy</a></small>
                </div>
            </div>
        </footer>
    )
}

export default Footer;