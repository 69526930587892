import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Routes from './routes';

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <main>
        <Routes />
      </main>
      <Footer />
    </div>
  );
}

export default App;
