import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Main from './components/Main';
import Privacy from './components/Privacy';

const Routes = () => (
    <BrowserRouter>
      <Switch>
        <Route path='/privacy' component={Privacy} />
        <Route path='/' component={Main} />
      </Switch>
    </BrowserRouter>
);

export default Routes;