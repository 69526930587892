import React, { useEffect } from 'react';
import M from 'materialize-css';

const Navbar = () => {
    useEffect(() => {
        let elements = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elements );
    },[])

    return (
        <div>
            <div className="navbar">
                <nav className="nav-wrapper black">
                    <div className="container">
                        <a href='/' className="brand-logo">Tibuba</a>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navbar;